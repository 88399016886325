const months = [
  {
    abbreviation: "Jan",
    name: "January",
    index: 1,
  },
  {
    abbreviation: "Feb",
    name: "February",
    index: 2,
  },
  {
    abbreviation: "Mar",
    name: "March",
    index: 3,
  },
  {
    abbreviation: "Apr",
    name: "April",
    index: 4,
  },
  {
    abbreviation: "May",
    name: "May",
    index: 5,
  },
  {
    abbreviation: "Jun",
    name: "June",
    index: 6,
  },
  {
    abbreviation: "Jul",
    name: "July",
    index: 7,
  },
  {
    abbreviation: "Aug",
    name: "August",
    index: 8,
  },
  {
    abbreviation: "Sep",
    name: "September",
    index: 9,
  },
  {
    abbreviation: "Oct",
    name: "October",
    index: 10,
  },
  {
    abbreviation: "Nov",
    name: "November",
    index: 11,
  },
  {
    abbreviation: "Dec",
    name: "December",
    index: 12,
  },
];

export default months;
